<template>
  <div><div class="title">退货流程</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-04-11 20:16:20
  </div><div data-v-0ab10f90="" style="font-size: 14px;"><div class="clearfix">&nbsp;</div>
    <div class="container">
      <div class="page-container no-border">
        <div>
          如果您不方便自助办理退货或有不清楚的地方，可直接联系{{ $tp().OPEN_COM_NAME }}客服，客服电话：
          <h5>{{ $tp().OPEN_PHONE }}</h5>
        </div>
        <div>退货成功后，{{ $tp().OPEN_COM_NAME }}会尽快将您最终实际支付的金额（扣除优惠）返到您的账户余额上。</div>
        <div style="margin-top:25px">
          <ol>
            <li>请将所需退回的商品（若订单中含有赠品需一并退回）一起退回</li>
            <li>如您未经{{ $tp().OPEN_COM_NAME }}同意，直接将商品寄回，{{ $tp().OPEN_COM_NAME }}将不负责保管和退款！</li>
            <li>因拍摄灯光及不同显示器色差等问题可能造成商品图片与实物有色差，以实物为准。</li>
            <li>建议妥善保管商品的外包装至少15天</li>
            <li>由于海关监管要求，{{ $tp().OPEN_COM_NAME }}暂不支持换货服务，如有需要您可重新购买</li>
          </ol>
        </div>
      </div>
    </div></div></div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:40
 * @version 1.0
 * @description
 */
export default {
  name: 'RefundProcess'
}
</script>

<style scoped>

</style>
